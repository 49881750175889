import React from 'react'

const SGBdashboard = (props) => {
  return (
    <div>
      <h1>SGB</h1>
        <h1>Wallet:         {props.sWalletAddress}</h1>
        <h1>sToadz:         {props.sToadzAmount}</h1>
        <h1>Songbird City:  {props.SBCamount}</h1>
        <h1>Luxury Lofts:   {props.LLamount}</h1>
    </div>
  )
}

export default SGBdashboard;