import "./App.css";
import { Xumm } from "xumm";
import SGBdashboard from "./SGBdashboard";
import XRPLdashboard from "./XRPLdashboard";
import { useState, useMemo, useEffect } from "react";
import loginWithSGB from "./SGBconnect";
import axios from "axios";

// global variables
const xrpl = require("xrpl");
const Web3 = require("web3");
const xumm = new Xumm("a69ccca5-e2b8-4a93-aa68-f1da318646ec");
const sToadzcontract = "0x35afb6Ba51839dEDD33140A3b704b39933D1e642";
const SBCcontract = "0x360f8b7d9530f55ab8e52394e6527935635f51e7";
const LLcontract = "0x91aa85a172dd3e7eea4ad1a4b33e90cbf3b99ed8";
const SGBURL =
  "https://songbird-explorer.flare.network/api?module=account&action=tokenbalance&contractaddress=";
const sToadzURL = `${SGBURL}${sToadzcontract}`;
const sbcURL = `${SGBURL}${SBCcontract}`;
const llURL = `${SGBURL}${LLcontract}`;
const SGBaddressPrefix = "&address=";
const web3Msg =
  "No Web3 detected, for SGB install Metamask or use Bifrost browser for mobile";

function App() {
  // States
  const [SGBwallet, setSGBwallet] = useState("");
  const [sToadzTotal, setsToadzTotal] = useState("0");
  const [SBCtotal, setSBCtotal] = useState("0");
  const [LLtotal, setLLtotal] = useState("0");
  const [xToadzTotal, setxToadzTotal] = useState("0");
  const [xxlTotal, setxxlTotal] = useState("0");
  const [XRPLaccount, setXRPLAccount] = useState("");
  const [discordAccount, setDiscordAccount] = useState("");
  const [discordName, setDiscordName] = useState("");
  const [discordAvatar, setDiscordAvatar] = useState("");
  const [discordId, setDiscordId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [SGBlogin, setSGBlogin] = useState(false);
  const [XRPLlogin, setXRPLlogin] = useState(false);
  const [discordlogin, setDiscordlogin] = useState(false);
  const [noweb3, setNoWeb3] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [success, setSuccess] = useState(false);

  // Variables
  const SGBDisplayWallet = `${SGBwallet[0]}${SGBwallet[1]}${SGBwallet[2]}${SGBwallet[3]}....${SGBwallet[38]}${SGBwallet[39]}${SGBwallet[40]}${SGBwallet[41]}`;
  const XRPLwallet = `${XRPLaccount}`;
  const XRPLDisplayWallet = `${XRPLwallet[0]}${XRPLwallet[1]}${XRPLwallet[2]}${XRPLwallet[3]}....${XRPLwallet[31]}${XRPLwallet[32]}${XRPLwallet[33]}`;
  const discordAvatarURL = `https://cdn.discordapp.com/avatars/${discordId}/${discordAvatar}.jpg`;

  // onload check web3
  window.onload = async (event) => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else {
      setNoWeb3(true);
    }
    if (window.localStorage.getItem("userSGBWalletAddress") !== null) {
      setSGBwallet(`${window.localStorage.getItem("userSGBWalletAddress")}`);
      setSGBlogin(true);
    }
    if (window.localStorage.getItem("userXRPWalletAddress") !== null) {
      setXRPLAccount(`${window.localStorage.getItem("userXRPWalletAddress")}`);
      setXRPLlogin(true);
    }
  };

  // functions

  const getdisco = async () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];
    axios
      .get("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      })
      .then((response) => {
        const username = response.data.username;
        const discriminator = response.data.discriminator;
        const id = response.data.id;
        const avatar = response.data.avatar;

        setDiscordAccount(`${username}#${discriminator}`);
        setDiscordName(username);
        setDiscordId(id);
        setAccessToken(accessToken);
        setDiscordAvatar(avatar);
        setDiscordlogin(true);
      })
      .catch(console.error);
  };

  const getX = async (XRPLwallet) => {
    // Define the network client
    const PUBLIC_SERVER = "wss://s1.ripple.com/";
    const client = new xrpl.Client(PUBLIC_SERVER);
    await client.connect();

    let xToadznum = 0;
    let xToadzXXLnum = 0;

    let response = await client.request({
      command: "account_nfts",
      account: `${XRPLwallet}`,
      limit: 400,
      ledger_index: "validated",
    });

    const ledger_data_index = response.result.ledger_index;

    do {
      for (let i = 0; response.result.account_nfts.length > i; i++) {
        if (
          response.result.account_nfts[i].Issuer ===
          "rBwoDxDHHRkVq7jtTcjD9YG1TUFvpPvGXx"
        ) {
          if (response.result.account_nfts[i].NFTokenTaxon === 1) {
            xToadznum += 1;
          } else if (response.result.account_nfts[i].NFTokenTaxon === 2) {
            xToadzXXLnum += 1;
          }
        }
      }

      if (response.result.marker === undefined) {
        break;
      }

      // Specify the same ledger and add the marker to continue querying.
      const ledger_marker = await client.request({
        command: "account_nfts",
        account: `${XRPLwallet}`,
        ledger_index: ledger_data_index,
        limit: 400,
        marker: response.result.marker,
      });
      response = ledger_marker;
    } while (true);

    setxToadzTotal(xToadznum);
    setxxlTotal(xToadzXXLnum);

    // Disconnect
    client.disconnect();
    return response;
  };

  const postData = () => {
    const discordMsg = {
      discordId: discordId,
      discordAccount: discordAccount,
      discordToken: accessToken,
      SGBaddress: SGBwallet,
      XRPLaddress: XRPLwallet,
      sToadz: sToadzTotal,
      SongBirdCity: SBCtotal,
      LuxuryLofts: LLtotal,
      xToadz: xToadzTotal,
      xToadzXXL: xxlTotal,
    };
    // http://localhost:5050/discordAPI
    // https://xtoadzwalletverify.herokuapp.com/discordAPI

    try {
      axios.post("https://roles-back-end.onrender.com/discordAPI", discordMsg);
    } catch (e) {
      console.log("Failed to post discord data");
    }
  };

  const calcSribbits = (sToadzTotal, SBCtotal, LLtotal) => {
    if (
      Number(sToadzTotal) === 0 ||
      Number(SBCtotal) === 0 ||
      Number(LLtotal) === 0
    ) {
      let data = "0";
      return data;
    } else {
      let data =
        (Number(sToadzTotal) + Number(SBCtotal) + Number(LLtotal)) * 4000;
      return data;
    }
  };

  const calcRibbits = (xToadzTotal, xxlTotal) => {
    if (xToadzTotal < 2 && xxlTotal === 0) {
      let data = "0";
      return data;
    } else {
      let data = (Number(xToadzTotal) + Number(xxlTotal)) * 1000;
      return data;
    }
  };

  // memos

  const getWeeklySribbits = useMemo(
    () => calcSribbits(sToadzTotal, SBCtotal, LLtotal),
    [sToadzTotal, SBCtotal, LLtotal]
  );
  const getWeeklyRibbits = useMemo(
    () => calcRibbits(xToadzTotal, xxlTotal),
    [xToadzTotal, xxlTotal]
  );

  // onClicks

  const SBGbuttonClick = () => {
    loginWithSGB().then((response) => {
      console.log("Logged in with SGB");
      setSGBwallet(`${window.userSGBWalletAddress}`);
      setSGBlogin(true);
    });
  };

  const XRPLbuttonClick = async () => {
    await xumm.authorize().then((response) => {
      console.log(response);
      console.log("Logged in with Xumm");
      window.localStorage.setItem("userXRPWalletAddress", response.me.account);
      window.userXRPWalletAddress = response.me.account;
      setXRPLAccount(response.me.account);
      setXRPLlogin(true);
    });
  };

  // useEffects

  // SGB get NFT amounts
  useEffect(() => {
    if (SGBlogin === true) {
      axios
        .get(`${sToadzURL}${SGBaddressPrefix}${SGBwallet}`)
        .then((response) => {
          setsToadzTotal(response.data.result);
        });
      axios.get(`${sbcURL}${SGBaddressPrefix}${SGBwallet}`).then((response) => {
        setSBCtotal(response.data.result);
      });
      axios.get(`${llURL}${SGBaddressPrefix}${SGBwallet}`).then((response) => {
        setLLtotal(response.data.result);
      });
      setSuccess(true);
    }
  }, [toggle, SGBlogin, SGBwallet]);

  // XRPL get NFT amounts
  useEffect(() => {
    if (XRPLlogin === true) {
      getX(XRPLwallet);
      setSuccess(true);
    }
  }, [toggle, XRPLlogin, XRPLwallet]);

  // get Disord data
  useEffect(() => {
    getdisco();
  }, []);

  // post discord API data
  useEffect(() => {
    if (discordlogin === true) {
      postData();
    }
  }, [
    toggle,
    sToadzTotal,
    SBCtotal,
    LLtotal,
    XRPLaccount,
    xToadzTotal,
    xxlTotal,
  ]);

  useEffect(() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else {
      setNoWeb3(true);
    }
  }, []);

  const refresh = () => {
    setToggle(!toggle);
  };

  return (
    <div className="App">
      <>
        <h1 className="Title">xToadz Wallet Verification</h1>
        {noweb3 === true ? <h1>{web3Msg}</h1> : ""}
        <h1 className="welcome">Welcome {discordName}</h1>
        {discordName !== "" ? (
          <img className="avatar" src={discordAvatarURL} alt="avatar" />
        ) : (
          ""
        )}
        {success === true ? (
          <h1 className="success">
            Your role/s will be updated shortly, or you can now return to
            discord and use Update roles.
          </h1>
        ) : (
          ""
        )}
        {discordlogin === false ? (
          <div>
            <a
              id="login"
              href="https://discord.com/api/oauth2/authorize?client_id=1077702243987501056&redirect_uri=https%3A%2F%2Fxtoadzroles.com%2F&response_type=token&scope=identify"
              className="bg-discord-blue  text-xl px-5 py-3 rounded-md font-bold flex items-center space-x-4 hover:bg-gray-600 transition duration-75"
            >
              <i className="fa-brands fa-discord text-2xl"></i>
              <button className="discordButton">Login with Discord</button>
            </a>
          </div>
        ) : (
          ""
        )}
        {discordlogin === true ? (
          <section className="buttons">
            {SGBlogin === false && noweb3 === false ? (
              <button className="SGBbutton" onClick={SBGbuttonClick}>
                Log in with SGB
              </button>
            ) : (
              ""
            )}

            <br></br>
            {XRPLlogin === false ? (
              <button className="XRPLbutton" onClick={XRPLbuttonClick}>
                Log in with Xumm
              </button>
            ) : (
              ""
            )}
          </section>
        ) : (
          ""
        )}
      </>
      <>
        {SGBlogin === true ? (
          <section className="SGB">
            <SGBdashboard
              sWalletAddress={SGBDisplayWallet}
              sToadzAmount={sToadzTotal}
              SBCamount={SBCtotal}
              LLamount={LLtotal}
            />
            <h1>Weekly sRIBBITs: {getWeeklySribbits}</h1>
          </section>
        ) : (
          ""
        )}
        {XRPLlogin === true ? (
          <section className="XRPL">
            <XRPLdashboard
              xWalletAdress={XRPLDisplayWallet}
              xToadzAmount={xToadzTotal}
              xToadzXXLamount={xxlTotal}
            />
            <h2>Weekly RIBBITS: {getWeeklyRibbits}</h2>
          </section>
        ) : (
          ""
        )}
      </>
      <br></br>
      {SGBlogin === true || XRPLlogin === true ? (
        <button className="refreshButton" onClick={refresh}>
          Refresh
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
export default App;
