import React from 'react'

const XRPLdashboard = (props) => {
  return (
    <div>
        <h1>XRPL</h1>
        <h1>Wallet:         {props.xWalletAdress}</h1>
        <h1>xToadz:         {props.xToadzAmount}</h1>
        <h1>xToadzXXL:      {props.xToadzXXLamount}</h1>   
    </div>
  )
}

export default XRPLdashboard;